<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('leave_with_consent_form')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('leave_with_consent_form')"
                              :isFilter="false"/>
            </template>

            <loading v-show="pageLoading"></loading>
            <div v-show="!pageLoading">
                <info-collapse-area>
                    <template v-slot:title>
                        {{ getObjectValue(user.active_student_program, 'student_number') }}, {{ getObjectValue(user, 'full_name') }}
                    </template>
                    <template v-slot:detail>
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('student_number')">
                                    <div class="label-as-input">
                                        {{ getObjectValue(user.active_student_program, 'student_number') }}
                                    </div>
                                </b-form-group>
                                <b-form-group :label="$t('full_name')">
                                    <div class="label-as-input">
                                        {{ getObjectValue(user, 'full_name') }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('faculty')">
                                    <div class="label-as-input">
                                        {{ user.active_student_program.faculty_name }}
                                    </div>
                                </b-form-group>
                                <b-form-group :label="$t('program')">
                                    <div class="label-as-input">
                                        {{ user.active_student_program.program_name }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('tc_or_passport')">
                                    <div class="label-as-input">
                                        {{ getObjectValue(user.active_student_program, 'national_id') }}
                                    </div>
                                </b-form-group>
                                <b-form-group :label="$t('nationality')">
                                    <div class="label-as-input">
                                        {{ user.person.nationality_name }}
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>
                </info-collapse-area>
                <div v-if="formErrorMessage">
                    <warning-page :message="formErrorMessage"/>
                </div>
                <div v-else>
                    <b-row>
                        <b-col cols="12" md="5" lg="4">
                            <h6 class="mt-5 mb-2">{{ $t('contact_information') }}</h6>
                            <div class="border p-4 rounded">
                                <b-form-group :label="$t('email')">
                                    <div class="label-as-input">
                                        {{ getObjectValue(studentData, 'email') }}
                                    </div>
                                </b-form-group>
                                <b-form-group :label="$t('gsm_no')">
                                    <b-input-group class="mb-3">
                                        <div class="label-as-input flex-grow-1">
                                            {{ getObjectValue(studentData, 'mobile_tel') }}
                                        </div>
                                        <b-input-group-append>
                                            <b-button variant="primary"
                                                      @click="mobileNumberUpdateModalShow()"
                                                      v-b-tooltip.hover
                                                      :title="$t('edit')"
                                            >
                                                <i class="ri-edit-box-line"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group :label="$t('address')">
                                    <b-input-group class="mb-3">
                                        <div class="label-as-input flex-grow-1 height-100">
                                            {{ getObjectValue(studentData, 'address') }}
                                        </div>
                                        <b-input-group-append>
                                            <b-button variant="primary"
                                                      @click="addressUpdateModalShow()"
                                                      v-b-tooltip.hover
                                                      :title="$t('edit')"
                                            >
                                                <i class="ri-edit-box-line"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col cols="12" md="7" lg="8">
                            <h6 class="mt-5 mb-2">{{ $t('request_information') }}</h6>
                            <div class="border p-4 rounded mb-2">
                                <ValidationObserver ref="leaveForm">
                                    <b-row>
                                        <b-col cols="12">
                                            <ValidationProvider name="reason" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('leave_with_consent_reason')">
                                                    <parameter-selectbox v-model="leaveForm.reason"
                                                                         code="leave_with_consent_reasons"
                                                                         :validate-error="errors[0]"
                                                                         :withoutItems="explanationWithoutItems"
                                                    />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12">
                                            <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('explanation')">
                                                    <b-form-textarea v-model="leaveForm.explanation"
                                                                     :state="errors[0] ? false : null"
                                                                     rows="3"
                                                    />
                                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group>
                                                <div>
                                                    <b-form-checkbox v-model="leaveForm.confirmation">
                                                        <span v-html="$t('leave_with_consent_statement1')"></span>
                                                    </b-form-checkbox>
                                                </div>
                                            </b-form-group>
                                            <b-form-group>
                                                <div @click="openStatement2Modal">
                                                    <b-form-checkbox v-model="leaveForm.approval"
                                                                     :disabled="!leaveForm.approval">
                                                        <span v-html="$t('statement_2')" @click="openStatement2Modal"></span>
                                                    </b-form-checkbox>
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                </ValidationObserver>
                            </div>
                            <div>
                                <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <CommonModal ref="addressUpdateModal"
                                 :onHideOnlyX="true"
                                 @bHideModalHeaderClose="formClear">
                        <template v-slot:CommonModalTitle>
                            <div>{{ $t('edit') }}</div>
                        </template>
                        <template v-slot:CommonModalContent>
                            <address-update v-if="formProcess=='address-update'"
                                            @addressUpdated="addressUpdateModalSuccess"
                            />
                        </template>
                    </CommonModal>
                    <CommonModal ref="mobileNumberUpdateModal"
                                 :onHideOnlyX="true"
                                 @bHideModalHeaderClose="formClear">
                        <template v-slot:CommonModalTitle>
                            <div>{{ $t('edit') }}</div>
                        </template>
                        <template v-slot:CommonModalContent>
                            <mobile-number-update v-if="formProcess=='mobile-number-update'"
                                                  :mobile_telephone="studentData.mobile_tel ? studentData.mobile_tel:''"
                                                  @mobileNumberUpdated="mobileNumberUpdateModalSuccess()"
                            />
                        </template>
                    </CommonModal>

                    <!-- Statement Modals -->
                    <CommonModal ref="statementModal2" size="xl" :footer="true">
                        <template v-slot:CommonModalContent>
                            <div class="font-weight-semibold mb-2">{{ $t('clarification_text') }}</div>
                            <div class="text-justify" style="white-space: pre-line;" v-if="$i18n.locale && $i18n.locale == 'TR'">
                                {{ $t('leave_with_consent_statement2_content') }}
                            </div>
                            <div class="auto-as-parent" v-else>
                                <img class="auto-as-parent" :src="engClarificationText_1" alt="..."/>
                                <img class="auto-as-parent" :src="engClarificationText_2" alt="..."/>
                                <img class="auto-as-parent" :src="engClarificationText_3" alt="..."/>
                            </div>
                        </template>
                        <template v-slot:CommonModalFooter>
                            <b-button variant="primary" type="button" @click="checkStatement2">{{$t('read_and_accept')}}</b-button>
                        </template>
                    </CommonModal>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout.vue';
    import Header from '@/layouts/AppLayout/Header.vue';
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile.vue';

    // Component
    import CitySelectbox from '@/components/interactive-fields/CitySelectbox';
    import CommonModal from '@/components/elements/CommonModal';
    import DistrictOutlineSelectbox from '@/components/interactive-fields/DistrictOutlineSelectbox'
    import InfoCollapseArea from '@/components/elements/InfoCollapseArea';
    import Loading from '@/components/elements/Loading';
    import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import SmsInput from '@/components/elements/SmsInput';
    import WarningPage from '@/components/page/WarningPage';

    // Pages
    import AddressUpdate from './AddressUpdate.vue';
    import MobileNumberUpdate from './MobileNumberUpdate.vue';

    // Services
    import LeaveWithConsentService from '@/services/LeaveWithConsentService';
    import PersonService from '@/services/PersonService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import isset from 'isset-php'

    // File
    import engClarificationText1 from "@/assets/img/eng_1.jpg"
    import engClarificationText2 from "@/assets/img/eng_2.jpg"
    import engClarificationText3 from "@/assets/img/eng_3.jpg"

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            CitySelectbox,
            CommonModal,
            DistrictOutlineSelectbox,
            InfoCollapseArea,
            Loading,
            MultiSelectbox,
            ParameterSelectbox,
            SmsInput,
            WarningPage,

            ValidationObserver,
            ValidationProvider,

            AddressUpdate,
            MobileNumberUpdate
        },
        data() {
            return {
                pageLoading: true,

                formLoading: null,
                formProcess: null,
                formErrorMessage: null,

                // Student
                studentData: {},

                engClarificationText_1:engClarificationText1,
                engClarificationText_2:engClarificationText2,
                engClarificationText_3:engClarificationText3,
                // Leave Form
                leaveForm: {
                    program: [],
                    reason: null,
                    explanation: null,
                    confirmation: false,
                    approval: false
                }
            }
        },
        metaInfo() {
            return {
                title: this.$t('leave_with_consent_form')
            }
        },
        computed: {
            user() {
                return this.$store.getters['auth/getUser'];
            },
            explanationWithoutItems() {
                let user = this.$store.getters['auth/getUser'];
                if (isset(() => user.person.gender) && user.person.gender == 'Kadın') {
                    return 'lwc13';
                }
                return '';
            }
        },
        async created() {
            await this.getStudent()
            await this.getAddress()
        },
        methods: {
            // Get
            getStudent() {
                this.pageLoading = true;
                LeaveWithConsentService.studentData()
                                       .then(response => {
                                           this.studentData = response.data.data
                                       })
                                       .catch((e) => {
                                           this.formErrorMessage = this.$t('api.' + e.data.message)
                                       })
                                       .finally(() => {
                                           this.pageLoading = false
                                       })
            },
            getAddress() {
                this.studentData.address = ''
                PersonService.getAddress()
                             .then(response => {
                                 let data = response.data.data
                                 this.studentData.address = data.contact_address + ' ' + data.contact_district_name + '/' + data.contact_city_name
                             })
            },

            // Clear
            formClear() {
                this.formProcess = null
            },

            // Address
            addressUpdateModalShow() {
                this.formProcess = 'address-update'
                this.$refs.addressUpdateModal.$refs.commonModal.show()
            },
            addressUpdateModalSuccess() {
                this.formProcess = ''
                this.$refs.addressUpdateModal.$refs.commonModal.hide()
                this.getAddress();
            },

            // Mobile Number
            mobileNumberUpdateModalShow() {
                this.formProcess = 'mobile-number-update'
                this.$refs.mobileNumberUpdateModal.$refs.commonModal.show()
            },
            mobileNumberUpdateModalSuccess() {
                this.formProcess = ''
                this.$refs.mobileNumberUpdateModal.$refs.commonModal.hide()
                this.getStudent()
            },

            checkStatement1() {
                this.leaveForm.confirmation = true
                this.$refs.statementModal1.$refs.commonModal.hide()
            },

            // Statement 2
            openStatement2Modal() {
                this.$refs.statementModal2.$refs.commonModal.show()
            },
            checkStatement2() {
                this.leaveForm.approval = true
                this.$refs.statementModal2.$refs.commonModal.hide()
            },

            // Send Leave Form
            async saveForm() {
                const isValid = await this.$refs.leaveForm.validate();
                if (isValid) {
                    this.$swal
                        .fire({
                            text: this.$t('are_you_sure_you_want_to_update_the_approval_status'),
                            showCancelButton: true,
                            confirmButtonText: this.$t('yes'),
                            cancelButtonText: this.$t('no')
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                let formData = {
                                    student_program_id: this.studentData.student_program.student_program_id,
                                    mobile_tel: this.leaveForm.mobile_tel,
                                    reason: this.leaveForm.reason,
                                    explanation: this.leaveForm.explanation,
                                    is_confirmation: this.leaveForm.confirmation == true ? 1 : 0,
                                    is_approval: this.leaveForm.approval == true ? 1 : 0
                                }
                                LeaveWithConsentService.storeStudentData(formData)
                                                       .then(response => {
                                                           this.$toast.success(this.$t('api.' + response.data.message))
                                                       })
                                                       .then(() => {
                                                           this.$router.push('/my-requests/1')
                                                       })
                                                       .catch(e => {
                                                           this.showErrors(e, this.$refs.leaveForm)
                                                       })
                            }
                        })
                }
            }
        }
    }
</script>

<style>

.auto-as-parent{
    height: 100%;
    width: 100%;
}

</style>
